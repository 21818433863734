import { Box, Button, Col, Row, Text } from '@qonsoll/react-design'
import { CardElement, useStripe } from '@stripe/react-stripe-js'

import moment from 'moment'
import PropTypes from 'prop-types'
import { Grid, Radio, Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { useGetPaymentMethod } from 'domains/User/hooks/get'
import { PaymentCardMethod } from 'modules/stripe-module/components'
import { useTranslations } from 'contexts/Translation'

const CheckoutForm = (props) => {
  const {
    loading,
    submitText,
    onSubmit,
    isPaymentMethodActive,
    paymentMethodId
  } = props

  const [isComplete, setIsComplete] = useState(false)
  const [selectedOldCard, setSelectedOldCard] = useState(true)

  const history = useHistory()
  const stripe = useStripe()

  const [paymentMethod, loadingPaymentMethod, error] = useGetPaymentMethod(
    paymentMethodId,
    isPaymentMethodActive
  )
  const card = useMemo(() => paymentMethod?.card || {}, [paymentMethod?.card])
  const isCard = useMemo(
    () => paymentMethod?.type === 'card' && !!card,
    [paymentMethod?.type, card]
  )
  const isExpired = useMemo(() => {
    const cardExpirationDate = moment.utc().set({
      year: card.exp_year,
      month: card.exp_month - 1,
      day: 0,
      hour: 0,
      minute: 0,
      second: 0
    })
    const now = moment.utc().set({ day: 0, hour: 0, minute: 0, second: 0 })
    return cardExpirationDate < now
  }, [card])
  const showExistedPaymentMethod = useMemo(
    () => isPaymentMethodActive && !error && isCard && !isExpired,
    [isPaymentMethodActive, error, isCard, isExpired]
  )
  const showCardElement = useMemo(
    () =>
      !loadingPaymentMethod && (!showExistedPaymentMethod || !selectedOldCard),
    [loadingPaymentMethod, showExistedPaymentMethod, selectedOldCard]
  )
  const disableButton = useMemo(
    () =>
      (!showExistedPaymentMethod && isPaymentMethodActive && !error) ||
      (showCardElement && (!stripe || !isComplete)),
    [
      showExistedPaymentMethod,
      isPaymentMethodActive,
      error,
      showCardElement,
      stripe,
      isComplete
    ]
  )

  const { t } = useTranslations()

  const handleCancel = () => history.goBack()

  const { xs, md } = Grid.useBreakpoint()
  return (
    <form>
      {showExistedPaymentMethod && !loadingPaymentMethod && (
        <>
          <PaymentCardMethod
            brand={card.brand}
            last4={card.last4}
            expMonth={card.exp_month}
            expYear={card.exp_year}
          />
          <Box
            mb={4}
            display="flex"
            whiteSpace="nowrap"
            overflowX="auto"
            justifyContent="center">
            <Radio.Group
              size="middle"
              buttonStyle={xs || !md ? 'solid' : 'outline'}
              onChange={(val) => setSelectedOldCard(val?.target?.value)}
              disabled={loading}
              value={selectedOldCard}>
              <Radio.Button
                style={{ padding: '4px 8px' }}
                key="old"
                value={true}>
                {t('Use existed card')}
              </Radio.Button>
              <Radio.Button
                style={{ padding: '4px 8px' }}
                key="new"
                value={false}>
                {t('Enter new card')}
              </Radio.Button>
            </Radio.Group>
          </Box>
        </>
      )}
      {loadingPaymentMethod && (
        <Text display="flex" justifyContent="center" alignItems="center" mb={4}>
          <Box mr={2}>
            <Spin />
          </Box>
          {t('Loading')}...
        </Text>
      )}
      {showCardElement && (
        <Box mb={4} overflow="hidden">
          <CardElement onChange={(e) => setIsComplete(e.complete)} />
        </Box>
      )}
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col>
          <Button block onClick={handleCancel}>
            {t('Cancel')}
          </Button>
        </Col>
        <Col>
          <Button
            block
            disabled={disableButton}
            loading={loading}
            onClick={() => onSubmit(showCardElement)}
            type="primary">
            {submitText}
          </Button>
        </Col>
      </Row>
    </form>
  )
}

CheckoutForm.propTypes = {
  loading: PropTypes.bool,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  isPaymentMethodActive: PropTypes.bool,
  paymentMethodId: PropTypes.string
}

export default CheckoutForm
